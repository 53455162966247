export const packList = [
"core.json",
"core_encounter.json",
"rcore.json",
"bota.json",
"bota_encounter.json",
"dwl.json",
"dwl_encounter.json",
"litas.json",
"litas_encounter.json",
"tece.json",
"tece_encounter.json",
"tmm.json",
"tmm_encounter.json",
"uau.json",
"uau_encounter.json",
"wda.json",
"wda_encounter.json",
"eoec.json",
"eoep.json",
"fhvc.json",
"fhvp.json",
"har.json",
"jac.json",
"nat.json",
"ste.json",
"win.json",
"aof.json",
"aon.json",
"aon_encounter.json",
"bad.json",
"bad_encounter.json",
"btb.json",
"btb_encounter.json",
"hfa.json",
"ltr.json",
"ltr_encounter.json",
"otr.json",
"pap.json",
"ptr.json",
"rod.json",
"rod_encounter.json",
"rop.json",
"rop_encounter.json",
"rtr.json",
"rtr_encounter.json",
"bob.json",
"dre.json",
"hoth.json",
"iotv.json",
"promo.json",
"tdg.json",
"tdor.json",
"tftbw.json",
"apot.json",
"apot_encounter.json",
"bsr.json",
"bsr_encounter.json",
"dca.json",
"dca_encounter.json",
"eotp.json",
"eotp_encounter.json",
"ptc.json",
"ptc_encounter.json",
"tpm.json",
"tpm_encounter.json",
"tuo.json",
"tuo_encounter.json",
"rtdwl.json",
"rtdwl_encounter.json",
"rtnotz.json",
"rtnotz_encounter.json",
"rtptc.json",
"rtptc_encounter.json",
"rttcu.json",
"rttcu_encounter.json",
"rttfa.json",
"rttfa_encounter.json",
"blbe.json",
"blbe_encounter.json",
"blob_encounter.json",
"coh_encounter.json",
"cotr_encounter.json",
"fof_encounter.json",
"guardians_encounter.json",
"hotel_encounter.json",
"lol_encounter.json",
"mtt_encounter.json",
"wog_encounter.json",
"bbt.json",
"bbt_encounter.json",
"fgg.json",
"fgg_encounter.json",
"icc.json",
"icc_encounter.json",
"tcu.json",
"tcu_encounter.json",
"tsn.json",
"tsn_encounter.json",
"uad.json",
"uad_encounter.json",
"wos.json",
"wos_encounter.json",
"dsm.json",
"dsm_encounter.json",
"pnr.json",
"pnr_encounter.json",
"sfk.json",
"sfk_encounter.json",
"tde.json",
"tde_encounter.json",
"tsh.json",
"tsh_encounter.json",
"wgd.json",
"wgd_encounter.json",
"woc.json",
"woc_encounter.json",
"hote.json",
"hote_encounter.json",
"sha.json",
"sha_encounter.json",
"tbb.json",
"tbb_encounter.json",
"tcoa.json",
"tcoa_encounter.json",
"tdoy.json",
"tdoy_encounter.json",
"tfa.json",
"tfa_encounter.json",
"tof.json",
"tof_encounter.json",
"def.json",
"def_encounter.json",
"hhg.json",
"hhg_encounter.json",
"itd.json",
"itd_encounter.json",
"itm.json",
"itm_encounter.json",
"lif.json",
"lif_encounter.json",
"lod.json",
"lod_encounter.json",
"tic.json",
"tic_encounter.json",
"tskc.json",
"tskp.json",
];